@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,300&display=swap');


@tailwind components;
@tailwind utilities;

body{
    margin: 0;
}

    html {
        font-family: Poppins;
    }
